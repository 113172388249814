<template>
  <div class="faq" style="max-height: 100%">
    <div
      style="margin-bottom: 6rem; width: 100%"
      data-aos="fade-up"
      data-aos-delay="300">
      <h1 style="margin-top: 150px">
        Prohlášení o zásadách ochrany osobních údajů
      </h1>
      <div class="paragraph">
        <b>Vyskakovací okno:</b>
        Na našich webových stránkách používáme soubory cookie ke zlepšení
        uživatelského prostředí a pro účely nutné komunikace. Přečtěte si prosím
        prohlášení o zásadách ochrany osobních údajů.
      </div>
      <div class="paragraph">
        Stránka prohlášení o zásadách ochrany osobních údajů: Vážíme si vašeho
        zájmu o naše kurzy. Rádi bychom co nejpřehledněji Vám vysvětlili v níže
        popsaných zásadách, jaké osobní údaje Internetový institut z.s. na
        webových stránkách
        <a
          href="www.studiooo.cz"
          style="color: blue; text-decoration: underline">
          www.studiooo.cz
        </a>
        zpracovává a pro jaké účely a jak zpracování probíhá. Jako nezisková
        organizace používáme vaše data ke zlepšení našich služeb. Navštívením
        našich stránek Studiooo.cz souhlasíte se sběrem vašich dat tak, jak je
        to popsáno v těchto zásadách ochrany osobních údajů.
      </div>
      <div class="paragraph">
        <ol >
          <li>
            Správce osobních údajů
            <ol>
              <li>
                Správce osobních údajů je Internetový institut z.s., IČ
                07307853, se sídlem Jugoslávská 1058, 252 29 Dobřichovice,
                spolek zapsaný v obchodním rejstříku vedeném Městským soudem v
                Praze část L, vložka 70629.
              </li>
              <li>
                Předsedkyně spolku, paní Tereza Bartoníčková tímto prohlašuje,
                že veškeré osobní údaje zpracované správcem jsou přísně důvěrné.
                Správce s nimi nakládá v souladu s vnitrostátními právními
                předpisy i předpisy Evropské unie platnými v oblasti ochrany
                osobních údajů.
              </li>
              <li>
                Správce shromažďuje, uchovává a využívá Vaše osobní data ve
                smyslu zákona č. 110/2019 Sb. o zpracování osobních údajů (dále
                jen zákon o zpracování osobních údajů), resp. nařízení
                Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna
                2016, o ochraně fyzických osob v souvislosti se zpracováním
                osobních údajů a o volném pohybu těchto údajů a o zrušení
                směrnice 95/46/ES (dále jen “
                <b>nařízení GDPR”</b>
                ”). Jednotlivé účely, pro které správce osobní údaje zpracovává,
                jsou dále vymezeny.
              </li>
              <li>
                Správce tyto osobní údaje shromažďuje prostřednictvím svých
                internetových stránek na adrese Studiooo.cz (dále jen “
                <b>internetové stránky</b>
                ”).
              </li>
            </ol>
          </li>
          <li>
            Zpracovávané údaje
            <ol>
              <li>
                Na webových stránkách Studiooo. sbíráme údaje, které nám
                dobrovolně poskytnete (např. e-mail, jméno, titul) a automatické
                údaje sloužící ke zkvalitňování našich služeb a komunikaci
                (např. poslední přihlášení, výsledky testů, certifikáty).
              </li>
              <li>
                Rozsah kategorií zpracovávaných osobních údajů zahrnuje
                <ol>
                  <li>
                    <b>registrace uživatele:</b> zpracováváme Vaše jméno, e-mail,
                    titul, obor Vašeho zaměření či školu;
                  </li>
                  <li>
                    <b>zasílání sdělení a nabídka produktů a služeb:</b> pokud jste se
                    přihlásili k odběru novinek, tak zpracováváme Váš e-mail;
                  </li>
                </ol>
              </li>
              <li>
                <b>Délka zpracování:</b> Vaše osobní údaje zpracováváme po dobu
                nezbytně nutnou pro poskytování a zkvalitňování našich služeb, a
                to po dobu, kterou je správce povinen osobní údaje uchovávat
                podle obecně závazných právních předpisů nebo po dobu, na kterou
                jste správci případně udělili souhlas.
              </li>
              <li>
                Osobní údaje jsou správcem zpracovány manuálně i automatizovaně.
              </li>
            </ol>
          </li>
          <li>
            Osobní údaje zpracované na základě souhlasu
            <ol>
              <li>
                Pokud jsme od Vás získali souhlas ke zpracování osobních údajů,
                stalo se tak pro některý z následujících účelů:
                <ol>
                  <li>Registrace na vzdělávací platformě Studiooo.cz.</li>
                  <li>
                    Personalizace Vašeho profilu na vzdělávací platformě
                    Studiooo.cz, např. v souvislosti s uvedením oboru Vašeho
                    zájmu nebo postupu v rámci vzdělávacího kurzu.
                  </li>
                  <li>
                    Vystavení certifikátu o absolvování kurzu na vzdělávací
                    platformě Studiooo.cz.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Práva subjektu údajů
            <ol>
              <li>
                Jako subjekt údajů máte uvedená práva, která pro Vás z právních
                předpisů vyplývají a která můžete kdykoliv uplatnit. Jedná se o:
                <ol>
                  <li>
                    právo na přístup k osobním údajům, podle kterého máte právo
                    získat od správce informace o tom, jestli správce zpracoval
                    Vaše osobní údaje. Tuto informaci je správce povinen Vám bez
                    zbytečného odkladu předat. Obsah informace je dán
                    ustanovením článku 15 nařízení GDPR. Správce má právo za
                    poskytnutí informace požádat přiměřenou úhradu nepřevyšující
                    náklady nezbytné na poskytnutí informace;
                  </li>
                  <li>
                    právo na opravu nebo výmaz osobních údajů, popřípadě omezení
                    zpracování, podle kterého máte právo na to, nechat opravit
                    osobní údaje, které jsou nepřesné nebo nesprávné. Pokud již
                    Vaše osobní údaje nejsou potřebné pro účely, ke kterým byly
                    shromážděny, nebo jsou zpracovávány protiprávně, máte právo
                    požadovat jejich výmaz. Pokud nechcete žádat výmaz osobních
                    údajů, ale pouze dočasně omezit jejich zpracování, můžete
                    žádat o omezení zpracování;
                  </li>
                  <li>
                    právo požadovat vysvětlení v případě, že máte podezření, že
                    zpracování osobních údajů správcem je v rozporu s právními
                    předpisy;
                  </li>
                  <li>
                    právo na přenositelnost údajů, tj. právo získat osobní
                    údaje, které se Vás týkají, které jste poskytl správci, ve
                    strukturovaném, běžně používaném a strojově čitelném
                    formátu, blíže viz. čl. 20 GDPR;
                  </li>
                  <li>
                    právo vznést námitku proti zpracování osobních údajů, které
                    jsou zpracovávány pro účely splnění úkolu prováděného ve
                    veřejném zájmu nebo při výkonu veřejné moci nebo pro účely
                    ochrany oprávněných zájmů správce. Správce zpracování ukončí
                    bez zbytečného odkladu pokud neprokáže, že existuje
                    oprávněný zájem/důvod pro zpracování, který převažuje nad
                    Vaším zájmem, právy nebo svobodami;
                  </li>
                  <li>
                    právo souhlas se zpracováním osobních údajů kdykoliv
                    odvolat, pokud jste udělili správci souhlas se zpracováním
                    osobních údajů.
                  </li>
                  <li>
                    právo na stížnost, pokud se domníváte, že porušujeme Vaše
                    práva anebo nesprávně zpracováváme Vaše osobní údaje.
                    Stížnost je možné podat k Úřadu pro ochranu osobních údajů,
                    vice na www.uoou.cz.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Cookies
            <ol>
              <li>
                Soubory cookies jsou krátké textové soubory, které internetová
                stránka odešle do Vašeho prohlížeče. Umožňují internetové
                stránce zaznamenat informace o Vaší návštěvě, například zvolený
                jazyk a podobně, následující návštěva stránek tak pro Vás může
                být snazší a příjemnější. Soubory cookies jsou důležité, neboť
                bez nich by procházení sítě Internet bylo mnohem složitější.
                Soubory cookies umožňují lepší využití naší internetové stránky
                a přizpůsobení jejího obsahu Vašim potřebám; používá je téměř
                každá internetová stránka na světě. Soubory cookies jsou
                užitečné, protože zvyšují uživatelskou přívětivost opakovaně
                navštívené internetové stránky.
              </li>
              <li>
                Na internetových stránkách správce může využívat tyto druhy
                cookies:
                <ol>
                  <li>
                    Relační (tedy dočasné) soubory cookies nám umožňují
                    propojovat Vaše jednotlivé aktivity po dobu prohlížení
                    těchto internetových stránek. V okamžiku otevření okna
                    Vašeho prohlížeče se tyto soubory cookies aktivují a
                    deaktivují se po zavření okna Vašeho prohlížeče. Relační
                    cookies jsou dočasné a po zavření prohlížeče se všechny tyto
                    soubory vymažou.
                  </li>
                  <li>
                    Trvalé soubory cookies nám pomáhají Váš počítač
                    identifikovat, jestliže opětovně navštívíte naši
                    internetovou stránku. Další výhodou trvalých cookies je to,
                    že nám umožňují přizpůsobit naše internetové stránky Vašim
                    potřebám.
                  </li>
                </ol>
              </li>
              <li>
                V souladu s ustanovením § 89 odst. 3 zák. č. 127/2005 Sb., o
                elektronických komunikacích, v účinném znění, si Vás tímto
                dovolujeme informovat, že naše internetové stránky využívají pro
                svoji činnost soubory cookies, tedy Vaše soubory cookies, včetně
                trvalých, zpracováváme. A to zejména z důvodů:
                <ol>
                  <li>
                    provozu a správy našich služeb, jako je sledování výpadků a
                    ochrana proti spamu, podvodu a zneužití,
                  </li>
                  <li>
                    měření zapojení publika a statistik webů, abychom
                    porozuměli, jak jsou naše služby využívány.
                  </li>
                </ol>
              </li>
              <li>
                Internetové prohlížeče obvykle obsahují správu souborů cookies.
                V rámci nastavení Vašeho prohlížeče tak pravděpodobně můžete
                jednotlivé soubory cookies ručně mazat, blokovat či zcela
                zakázat jejich používání. Pro více informací použijte nápovědu
                svého internetového prohlížeče. Pokud použití souborů cookies
                nepovolíte, některé funkce a stránky nemusí fungovat, jak by
                měly.
              </li>
            </ol>
          </li>
          <li>
            Předávání do třetích zemí
            <ol>
              <li>Předávání do třetích zemí</li>
            </ol>
          </li>
          <li>
            Informace a otázky
            <ol>
              <li>
                Další informace o právech a povinnostech při ochraně osobních
                údajů může subjekt údajů získat na webových stránkách
                <a href="www.zakonyprolidi.cz">www.zakonyprolidi.cz</a>
                nebo prostřednictvím e-mailu na cesky@internetovyinstitut.cz.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.expands = new Array(5);
    this.expands = this.expands.fill(false);
  },

  data() {
    return {
      expands: [],
    };
  },
};
</script>

<style lang="scss" scoped>
b {
  font-weight: 600;
}

a {
  color: blue;
  text-decoration: underline;
}

ol {
  counter-reset: section; /* Creates a new instance of the
                                            section counter with each ol
                                            element */
  list-style-type: none;
  font-weight: 600;
  padding-left: 0;
}
ol ol {
  font-weight: normal;
  padding-left: 2rem;
}

li {
  margin-bottom: 0.5rem;

}

li::before {
  counter-increment: section; /* Increments only this instance
                                            of the section counter */
  content: counters(section, '.') '.'; /* Combines the values of all instances
                                            of the section counter, separated
                                            by a period */
  margin-right: 0.7rem;
}


.paragraph {
  color: #808b9d;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.rotate {
  transform: rotate(-180deg);
  transition: 0.3s all;
}

.expanded {
  display: flex;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
}

.expanded.open {
  max-height: 500px;
}

.faq {
  width: 100%;
}

.expand {
  margin-right: 1rem;
  width: 1.2rem;
  height: 1.2rem;

  transition: 0.3s ease-in-out;
}

.question {
  width: 100%;
  border: 1.5px solid #b6c1d1;
  margin-bottom: 1rem;
  min-height: 3rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0.7rem 0.5rem;
  width: 100%;
  justify-content: center;
  cursor: pointer;

  &__text {
    margin-left: 3.4rem;
    color: #808b9d;
    font-size: 0.9em;
    margin-top: 0.5rem;
    opacity: 0;
    transition: opacity 0.4s linear;
    transition-delay: 0.2s;
  }

  &__text.open {
    opacity: 1;
  }

  &__heading {
    display: flex;
    flex-direction: row;
    align-items: center;

    .circle {
      min-width: 2.5rem;
      min-height: 2.5rem;
      background: #e1e6ed;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.answer {
  max-width: 50%;
  font-size: 20;
  color: #808b9d;
  margin-bottom: 3rem;
}

@media (max-width: 1024px) {
  .faq {
    margin: 0 1.5rem;
  }
}
</style>
