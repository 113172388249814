<template>
  <div style="max-height: 100%">
    <div data-aos="fade-up" data-aos-delay="300">
      <h1 style="margin-top: 170px">O nás</h1>
      <div
        style="
          max-width: 50%;
          
          font-size: 20;
          color: #808b9d;
        ">
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Cras
        elementum. Vivamus porttitor turpis ac leo. Curabitur sagittis hendrerit
        ante.
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
